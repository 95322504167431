import type { IUISupplier, IWeddingSupplier, Slug } from '@bridebook/toolbox/src/types';
import type { IBasicSupplier } from 'app-shared/lib/supplier/supplier-types';
import { IElasticSupplier } from 'lib/supplier/types';
import { CustomSupplierFormType } from './reducer';

export interface IShortlistedBlockType extends IBasicSupplier {
  createdAt: number;
  countryCode?: string;
  booked: boolean;
  customSupplier: boolean;
  notes: string;
  professional: boolean;
  price?: number;
  updatedAt?: number;
  email?: string;
  visitDate?: string;
  statusUpdate?: string;
  tier?: number;
  type: Slug;
  checked?: boolean;
}

export interface IShortlistedBlockCustomType extends IShortlistedBlockType {
  phone: string;
  email: string;
  postcode: string;
  type: Slug;
}

export type SupplierStatus = 'shortlisted' | 'booked';

export type TShortlistTab = 'venues' | 'suppliers' | 'booked';

type SupplierType = 'customSupplier' | 'listed';

export enum ShortlistFilterTypes {
  ALL = 'all',
  CONTACTED = 'contacted',
  VISIT_DATE = 'visit date',
}

export type TUIShortlistSupplier = IUISupplier & IWeddingSupplier;
export type TShortlistList = Record<string, TUIShortlistSupplier>;

export interface IShortlistState {
  list: TShortlistList;
  loaded: boolean;
  initialised: boolean;
  customSupplier: CustomSupplierFormType;
  customSupplierFormMethod: string;
  addSupplierModalOpened: boolean;
  addSupplierModal: {
    triggerLocation: string | null;
  };
  // ID of a supplier opened inside the details drawer
  openedSupplierId: string | null;
  addToBooked: boolean;
  showSearchGoogleModal: boolean;
  showCustomSupplierFormModal: boolean;
  shortlistedLocation?: ShortlistedLocation;
  shortlistFilter: ShortlistFilterTypes;
  // Modal to confirm marking supplier as booked
  confirmBookingModal?: {
    supplierToBook: IUISupplier;
    triggerLocation: string;
  };
  fromBookedSuppliers: boolean;
  venueRecommendedSuppliers: IElasticSupplier[];
}

export interface ShortlistSupplierPropertiesGeneric {
  shortlistedSupplierCategory: string;
  shortlistSupplierId: string;
  shortlistSupplierName: string;
  shortlistedSupplierStatus: SupplierStatus;
  shortlistedSupplierType: SupplierType;
  supplierTier: number;
}

export type ShortlistedLocation =
  | 'search'
  | 'supplierProfile_adCarousel'
  | 'supplierCategoriesCarousel'
  | 'supplierRecommendedPhotographer'
  | 'recentlyViewedCarousel'
  | 'recentlyViewedSearchBar'
  | 'trendingVenuesCarousel'
  | 'yourMatchesCarousel'
  | 'venueRexCarousel'
  | 'venueRexRichCarousel'
  | 'emptyShortlistCarousel'
  | 'supplierProfile_upNext'
  | 'supplierProfile_actionMenu'
  | 'supplierProfile_photo'
  | 'supplierProfile_askForFeedbackModal'
  | 'supplierProfile_ratingToast'
  | 'shortlist'
  | 'home'
  | 'checklist'
  | 'onboarding'
  | 'onboarding_phone'
  | 'article'
  | 'venueConfirmationPopup'
  | 'supplierProfile_fullscreenImage'
  | 'weddingFairsCarousel'
  | 'weddingFairsPage'
  | 'specialOffersCarousel'
  | 'specialOffersPage'
  | 'realWeddingsCarousel'
  | 'trendingPhotographersCarousel'
  | 'linkedSupplier_weddingConfirmationPopup'
  | 'countdownWidget'
  | 'cakeQuiz'
  | 'photographyStyleQuiz'
  | 'venueRecommendedSuppliers'
  | 'photoQuiz'
  | 'cakesQuiz'
  | 'floristsQuiz'
  | 'beautyQuiz'
  | 'venueRecommendedSuppliersPage'
  | 'videographyStyleQuiz'
  | 'reviewsPage'
  | 'bookingConfirmationPopup'
  | 'map'
  | 'searchModalRecentlyViewedCarousel'
  | 'homeBookedSupplierWidget'
  | 'supplierProfile'
  | 'venueRexCarouselSearchNoResults'
  | 'venueRexCarouselEndSearchResults'
  | 'supplierMatching';

export type ShortlistedMethod =
  | 'tile'
  | 'button'
  | 'searchByName'
  | 'onboarding'
  | 'confirmVenuePopup'
  | 'confirmSupplierPopup'
  | 'onEnquirySend'
  | 'missingWeddingLocation'
  | 'distantWeddingDate'
  | 'venueRecommendedSuppliersPage'
  | 'askForFeedback'
  | 'saveFeedback'
  | 'swipedRight';

export type BookedMethod =
  | 'onboarding'
  | 'bookedPopup'
  | 'searchByName'
  | 'confirmVenuePopup'
  | 'button'
  | 'countdownWidget'
  | 'supplierProfile'
  | 'bookedSuppliersCarousel'
  | 'shortlistedFavourites'
  | 'searchLandingPage'
  | 'supplierProfile_actionMenu'
  | 'homeBookedSupplierWidget';
